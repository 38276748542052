import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  CASHIER_EXPRESS_IN_PRODUCT_PAGE: 'specs.stores.CashierExpressInProductPage',
  OPTIONS_FROM_VARIANT_CALCULATOR: 'specs.stores.OptionsFromVariantCalculatorOnProductPage',
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  URL_CUSTOMIZE: 'specs.stores.EnableUrlCustomizeInProductPage',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  UI_TPA_COLOR_OPTIONS: 'specs.stores.UiTpaColorPickerOptions',
  BASE_PRICE_IN_GALLERY: 'specs.stores.BasePriceInGallery',
  SELECTABLE_OUT_OF_STOCK: 'specs.stores.SelectableOOSOptionsPP',
  MIGRATE_VOLATILE_CART_API_TO_GRAPHQL_NODE: 'specs.stores.MigrateVolatileCartApiToGraphqlNode',
  CREATE_CART_VIA_GRAPHQL_NODE_WITH_PRODUCT_OPTIONS_OBJECT:
    'specs.stores.CreateCartViaGraphqlNodeWithProductOptionsObject',
  USE_CART_COMMANDS_OF_CHECKOUT_FROM_NODE: 'specs.stores.UseCartCommandsOfCheckoutFromNode',
  BACK_IN_STOCK_PRODUCT_PAGE_BUTTON: 'specs.stores.BackInStockProductPageButton',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  FIX_CUSTOM_URL_DEFAULT_URL_FALLBACK: 'specs.stores.FixCustomUrlDefaultUrlFallback',
  BACK_IN_STOCK_PRODUCT_PAGE_TEXT_SETTINGS: 'specs.stores.BackInStockProductPageTextSettings',
  MANAGE_PRODUCT_BRAND: 'specs.stores.ManageProductBrand',
  FAST_FLOW_ON_PLATFORMIZED_CHECKOUT: 'specs.stores.ProductPageFastFlowOnPlatformizedCheckout',
  USE_NEW_CART_API_FROM_SDK: 'specs.stores.ProductPageUseNewCartApiFromSdk',
  USE_CHECKOUT_ID_IN_FAST_FLOW: 'specs.stores.UseCheckoutIdInProductPageFastFlow',
} as const;

export enum clientSideBuildCheckoutUrlSpecValues {
  Closed = 'closed',
  Compare = 'compare',
  UseNewBuilder = 'useNewBuilder',
}
